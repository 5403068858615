import React, { Fragment, Suspense, useEffect } from 'react';
import { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faInternetExplorer } from '@fortawesome/free-brands-svg-icons';
import { faEarth, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import UseIntersectionObserver from "./movingComment";
import ImpressumPopUp from "./Impressumpopup";
import { AuthorDataProvider } from './AuthorDataProvider';
import { useParams } from 'react-router-dom';
import TeamImpressumData from './TeamImpressumData';
import debounce from 'debounce';

function useDynamicRefs() {
  const refsMap = useRef({});
  const getRef = (id) => {
    if (!refsMap.current[id]) {
      refsMap.current[id] = React.createRef();
    }
    return refsMap.current[id];
  };
  return getRef;
}


export default function Team({user, navi}) {
    const {id}=useParams();
    const [showModal, setShowModal] = useState(user==='info'?true:false);
    const [author, setAuthor] = useState();
    const refs = useRef([...Array(32)].map(() => React.createRef())); // Dynamically create refs if needed
    const authorsData = TeamImpressumData;
    const [size, setSize] = useState(() => resolveBreakpoint(window.innerWidth));
    
    // Simplified observer application, dynamically apply to all refs if they're truly needed
    UseIntersectionObserver(refs.current[0], { threshold: 0.1 }); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[1], { threshold: 0.5 });
  
    UseIntersectionObserver(refs.current[2], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[3], { threshold: 0.5 }); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[4], { threshold: 0.7 });
  
    UseIntersectionObserver(refs.current[5], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[6], { threshold: 0.5 }); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[7], { threshold: 0.7 });
  
    UseIntersectionObserver(refs.current[8], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[9], { threshold: 0.5 }); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[10], { threshold: 0.7 });
  
    UseIntersectionObserver(refs.current[11], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[12], { threshold: 0.5}); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[13], { threshold: 0.7 });
  
    UseIntersectionObserver(refs.current[14], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[15], { threshold: 0.5}); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[16], { threshold: 0.7 });
  
    UseIntersectionObserver(refs.current[17], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[18], { threshold: 0.5}); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[19], { threshold: 0.7 });
  
    UseIntersectionObserver(refs.current[20], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[21], { threshold: 0.5}); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[22], { threshold: 0.7 });

    UseIntersectionObserver(refs.current[23], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[24], { threshold: 0.5}); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[25], { threshold: 0.7 });

    UseIntersectionObserver(refs.current[26], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[27], { threshold: 0.5}); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[28], { threshold: 0.7 });

    UseIntersectionObserver(refs.current[29], { threshold: 0.1 });
    UseIntersectionObserver(refs.current[30], { threshold: 0.5}); // You can adjust the options as needed
    UseIntersectionObserver(refs.current[31], { threshold: 0.7 });
 
    const getRef = useDynamicRefs();
     // Refs für jeden Autor-Abschnitt
     const navigateToSection = (id) => {
      const ref = getRef(id);
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    useEffect(()=> {
      if(id){
      if(user=='info'){
        setAuthor(id);
      }
      navigateToSection(id)
    }
  },[]);
  function resolveBreakpoint( width){
    if ( width < 576 ) {
      return 2;
    } else if ( width >= 576 && width < 768 ) {
      return 3;
    } else if ( width >= 768 && width < 1100 ) {
      return 3;
    } else if ( width >= 1100 && width < 1200 ) {
      return 4;
    } else if ( width >= 1200 && width < 1440 ) {
      return 5;
    } else if ( width >= 1440 ) {
      return 5;
    }
  }
  useEffect(() => {
		const calcInnerWidth = debounce(function () {
			setSize(resolveBreakpoint(window.innerWidth));
		}, 200);
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);
  


    return (<>
    <Suspense fallback={<div>Loading...</div>}>
      {/* Hot Bar Bereich */}
  <div className="bg-teal-800 text-white p-4 sticky top-18 z-1 overflow-x-auto">
    <div className="flex justify-center space-x-2 sm:space-x-4">
    {authorsData.map(author => (author.id!='Corinna'?(
      <img
        key={author.id}
        src={author.portrait}
        alt={`Portrait ${author.name}`}
        className="h-12 w-12 rounded-full cursor-pointer hover:opacity-75"
        onClick={() => navigateToSection(author.id)}
      />
    ):null))}
        </div>
      </div>
      
    {authorsData.map((author) => (author.id!='Corinna'?(
    <div key={author.id} ref={getRef(author.id)} className="relative overflow-hidden ">
         <div className="absolute inset-0 bg-gradient"></div>
      <div className="absolute inset-0 bg-blur-image" style={{ backgroundImage: 'url('+author.backgroundImage+')'}}></div>
        <div className={size>3?("pb-80 unblurred-content pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40"):""}>
          <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
            <div className="sm:max-w-lg">
              <h1 ref={refs.current[author.index]} className="hiddeni text-4xl font-bold tracking-tight text-white sm:text-6xl" style={{marginTop:'10%'}}>
              {author.id}
              </h1>
              <p  ref={refs.current[author.index+1]} className="hiddeni mt-4 text-xl text-white">
              {author.description}
              </p>
              <div className="social-media-links" style={{paddingTop:'2%'}}>
                       {author.socialLinks.instagram &&(
                      <a href={author.socialLinks.instagram} className="icon instagram">
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>)}
                      {author.socialLinks.facebook && (
                      <a href={author.socialLinks.facebook} className="icon facebook">
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>)}
                    {author.socialLinks.website && (
                <a href={author.socialLinks.website}>
                  <FontAwesomeIcon icon={faEarth} className="icon website" />
                </a>
              )}

            <a  onClick={(e) => setShowModal(true)} className="icon impressum">
               <button onClick={(e) => {setAuthor(author.id); setShowModal(true); }} ><FontAwesomeIcon icon={faInfoCircle} /></button>
        
             </a>
                  </div>
                  <div className='mt-4 tracking-tight w-full'>
        <details  className=' text-xl text-white'>
          <summary style={{fontWeight:'lighter', fontStyle:'italic'}}>
            mehr Infos
          </summary>
          <div style={{marginTop: '1%'}}>
        {author.detailedExpo.split('\n\n').map((paragraph, idx) => (
        <p key={idx}>
       {paragraph.split('\n').map((line, lineIdx) => {
        // Zuerst den Text anhand der <f> und </f> Tags aufspalten
        const parts = line.split(/<f>|<\/f>/);
        return (
          <Fragment key={lineIdx}>
            {parts.map((part, partIdx) => {
              // Prüfen, ob der Teil fettgedruckt sein soll. Dies ist der Fall, wenn der Index ungerade ist.
              const isBold = partIdx % 2 !== 0;
              return isBold ? <strong key={partIdx}>{part}</strong> : part;
                   })}
                  {lineIdx < paragraph.split('\n').length - 1 ? <br /> : null}
                </Fragment>
                 );
                  })}
               </p>
             ))}
            </div>
        </details>
        </div>
            </div>
            {size>3?(
            <div>
              <div className="mt-10">
                {/* Decorative image grid */}
                <div
                  aria-hidden="true"
                  className="pointer-events-none absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
                >
                  <div className="absolute transform sm:left-1 sm:top-1 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                    <div className="flex items-center space-x-6 lg:space-x-8">
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div  className=" h-64 w-44 overflow-hidden rounded-lg sm:opacity-0 lg:opacity-100">
                          <img
                            src={author.portrait}
                            alt=""
                            loading="lazy"
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={author.images[0]}
                            loading="lazy"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={author.images[1]}
                            loading="lazy"
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-50 w-44 overflow-hidden rounded-lg">
                          <img
                            src={author.images[2]}
                            alt=""
                            loading="lazy"
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={author.images[3]}
                            alt=""
                            loading="lazy"
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                      <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={author.images[4]}
                            alt=""
                            loading="lazy"
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="h-64 w-44 overflow-hidden rounded-lg">
                          <img
                            src={author.images[5]}
                            alt=""
                            loading="lazy"
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                
              </div>
            </div>
             ):null}
          </div>
        </div>
       {size<4?(<div className="grid grid-cols-3 gap-2" style={{marginTop:'2%',marginBottom:'4%', marginLeft:'1%', marginRight:'1%'}}>
  <div className="overflow-hidden rounded-lg">
    <img
      src={author.portrait} // Stelle sicher, dass der Index für jedes Bild korrekt ist
      alt=""
      loading="lazy"
      className=" w-full object-cover object-center"
      style={{minHeight:size<3?'150px':'210px',maxHeight:size<3?'150px':'210px'}}
    />
  </div>
  <div className="overflow-hidden rounded-lg">
    <img
      src={author.images[2]} // Ändere den Index entsprechend
      alt=""
      loading="lazy"
      className="w-full object-cover object-center"
      style={{minHeight:size<3?'150px':'210px',maxHeight:size<3?'150px':'210px'}}
    />
  </div>
  <div className="overflow-hidden rounded-lg">
    <img
      src={author.images[3]} // Ändere den Index entsprechend
      alt=""
      loading="lazy"
      className=" w-full object-cover object-center"
      style={{minHeight:size<3?'150px':'210px',maxHeight:size<3?'150px':'210px'}}
    />
  </div>
</div>):null}
      </div>):null))}
      <AuthorDataProvider>
      {showModal && <ImpressumPopUp open={showModal} setOpen={setShowModal} authorName={author}/>}
      </AuthorDataProvider>
      <div className="flex sticky flex-col">
  {/* Hot Bar Bereich */}
  <div className="bg-teal-800 text-white p-4 sticky top-0 z-50 overflow-x-auto">
    <div className="flex justify-center space-x-2 sm:space-x-4">
    {authorsData.map(author => (author.id!='Corinna'?(
      <img
        key={author.id}
        src={author.portrait}
        alt={`Portrait ${author.name}`}
        className="h-12 w-12 rounded-full cursor-pointer hover:opacity-75"
        onClick={() => navigateToSection(author.id)}
      />
    ):null))}
        </div>
      </div>
      </div>
      </Suspense>
      </>
    )
  }